import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, filter, fromEvent } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  moduleLoading: boolean = false;
  menueLoaded = false;

  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(
    public navService: NavigationService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public router: Router
  ) {
    router.events.subscribe((event: any) => {
      this.navigationInterceptor(event);
    });
    // this.authenticationService.menuLoaded
    //   .subscribe(data => {
    //     if (data) {
    //       this.menueLoaded = true;
    //     }
    //   });

  }

  ngOnInit(): void {
    this.updateSidebar();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        if (this.isMobile()) {
          this.navService.sidebarState.sidenavOpen = false;
        }
      });


    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      let ref = this.snackBar.open(this.translate.instant('general.You are back online!'), this.translate.instant('swalMsg.Reload'), {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      ref.afterDismissed().subscribe(info => {
        if (info.dismissedByAction === true) {
          window.location.reload()
        }
      });
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.snackBar.open(this.translate.instant('general.You are offline!'), this.translate.instant('swalMsg.Ok'), {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isMobile() {
    return window && window.matchMedia('(max-width: 992px)').matches;
  }

  // Shows and hides the moduleLoading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.moduleLoading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.moduleLoading = false;
        this.snackBar.dismiss();
      }, 100);
    }

    // Set moduleLoading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
  }

  updateSidebar() {
    if (this.isMobile()) {
      this.navService.sidebarState.sidenavOpen = false;
    } else {
      this.navService.sidebarState.sidenavOpen = true;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.updateSidebar();
  }

}
