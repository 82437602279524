<div class="info-data" *ngIf="vendorDetails?.phone_number">
    <div class="icon-card">
        <img src="./assets/images/icons/ic_phone.svg" alt="">
    </div>
    <div>
        <label>{{'general.Mobile Number' | translate}}</label>
        <div class="always-ltr">+(966) {{vendorDetails?.phone_number}}</div>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.email">
    <div class="icon-card">
        <img src="./assets/images/icons/ic_email.svg" alt="">
    </div>
    <div>
        <label>{{'login.Email' | translate}}</label>
        <a href="mailto:{{vendorDetails?.email}}">{{vendorDetails?.email}}</a>
    </div>
</div>
<p class="font-14 fontRegular text-color mb-0" *ngIf="vendorDetails?.social_media_links.facebook_link 
|| vendorDetails?.social_media_links.instagram_link 
|| vendorDetails?.social_media_links.x_link">
    {{"vendorAdminProfile.Social media channels"|translate}}
</p>

<div class="info-data" *ngIf="vendorDetails?.social_media_links.facebook_link">
    <div class="icon-card">
        <img src="/assets/images/icons/facebook.svg" alt="">
    </div>
    <div>
        <label>{{'vendors.Facebook' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.facebook_link">{{vendorDetails?.social_media_links.facebook_link}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.social_media_links.instagram_link">
    <div class="icon-card">
        <img src="/assets/images/icons/instagram.png" alt="">
    </div>
    <div>
        <label>{{'vendors.Instagram' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.instagram_link">{{vendorDetails?.social_media_links.instagram_link}}</a>
    </div>
</div>
<div class="info-data" *ngIf="vendorDetails?.social_media_links.x_link">
    <div class="icon-card">
        <img src="/assets/images/icons/twitter.svg" alt="">
    </div>
    <div>
        <label>{{'vendors.X' | translate}}</label>
        <a target="_blank"
            [href]="vendorDetails?.social_media_links.x_link">{{vendorDetails?.social_media_links.x_link}}</a>
    </div>
</div>