import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './services/StorageService';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'wala-X-app';

  constructor(
    public storageService: StorageService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private _authService: AuthenticationService
    // private sw: UpdateAppService,
    // upToDateService: UpToDateBuildService
  ) {
    // this.sw.checkForUpdates();
    // let buildNumber = upToDateService.buildNumberAtStartup;
    // upToDateService.buildIsUpToDate.subscribe(buildIsUpToDate => {
    //   if (!buildIsUpToDate) {
    //     Swal.fire({
    //       title: this.translate.instant('general.New app version found.'),
    //       icon: 'info',
    //       confirmButtonText: this.translate.instant('swalMsg.Reload'),
    //     }).then(() => {
    //       window.location.reload();
    //     });
    //   }
    // });

    this.storageService.changes.subscribe(data => {
      if (data?.key == 'language' || data?.key == 'authUser') {
        window.location.reload();
      }
    });

    var lang = localStorage.getItem('language') || 'ar';
    translate.use(lang)
    var style = './assets/css/en-US/style.css';
    var dir = 'ltr';
    if (lang == 'ar') {
      style = './assets/css/ar-SA/style.css';
      dir = 'rtl';
    }
    let buildNumber = '1.0.3'
    style += buildNumber ? '?vr=' + buildNumber : '';
    document.getElementById('mainStyle')?.setAttribute('href', style);
    document.getElementById('html')?.setAttribute('lang', lang);
    document.getElementById('html')?.setAttribute('dir', dir);
  }
  ngOnInit(): void {
  }
}
