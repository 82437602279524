import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LookupsService } from '../../../services/lookups.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { IMenuItem, NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed',
        animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ])
  ]
})
export class SidebarComponent implements OnInit {
  nav: any[] = [];
  selectedItem: IMenuItem|null = null;

  constructor(
    public navService: NavigationService,
    public LookupsService: LookupsService,
    public authenticationService: AuthenticationService,
    public router: Router
  ) { }

  ngOnInit(): void {
    // if (location.href.includes("localhost")) {
    //   this.navService.menuItems.subscribe(items => {
    //     this.nav = items;
    //   });
    // } else {
      this.getMenuItems();
    // }
  }


  getMenuItems() {
    this.LookupsService.GetMenu().subscribe(
      (data: any) => {
        this.nav = data.data as any[];
        this.nav.map(item => {
          item.title = item.name;
          item.type = item.childParent && item.childParent.length ? 'dropDown' : 'link';
          item.icon = item.icon_menu;
          item.state =  item.link;
          if (item.childParent && item.childParent.length) {
            item.sub = item.childParent;
            item.sub.map((child: any) => {
              child.title = child.name;
              child.type = 'link';
              child.icon = child.icon_menu;
              child.state = child.link;
            });
          }
        })
        this.authenticationService.menuLoaded.next(this.nav);
      },
      (error: any) => {
        // this.authenticationService.logout();
      });
  }

  isActiveMenu(link: string) {
    return location.href.includes(link);
  }

  selectItem(item: IMenuItem) {
    if (item.active) {
      this.nav.forEach(element => {
        element.active = false;
      });
      this.selectedItem = null;
    } else {
      this.selectedItem = item;
      this.setActiveMainItem(item);
    }
  }

  setActiveMainItem(item: IMenuItem) {
    this.nav.forEach(element => {
      element.active = false;
    });
    item.active = true;
  }

  toggelSidebar() {
    const navState = this.navService.sidebarState;
    navState.sidenavOpen = !navState.sidenavOpen;
  }

}
