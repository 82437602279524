<div class="info-data">
    <div class="icon-card">
        <img src="./assets/images/icons/map-marker-icon.svg" alt="">
    </div>
    <div class="w-100">
        <label>{{'vendors.Vendor Address' | translate}}</label>
        <div> <span *ngIf="vendorDetails?.address">{{vendorDetails?.address}} ,</span>{{vendorDetails?.city_name}}
        </div>
        <div class="w-100">
            <google-map width="100%" height="300px" id="customMap4" [options]="mapOptions">
                <map-marker *ngIf="Marker" [options]="Marker"></map-marker>
            </google-map>
        </div>

    </div>
</div>