<!-- <div class="dialog">
    <div class="dialog_header">

        <button mat-icon-button class="close" [disabled]="loading" (click)="dialogRef.close(notificationList.length)"><svg
            xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="ic_close"
                d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                transform="translate(-204.746 755.5)" fill="#999" />
        </svg>
    </button>
        {{ "general.notifications" | translate }}
    </div> -->
<div class="notification_list p-0" mat-dialog-content infiniteScroll [scrollWindow]="false" 
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="notificationListModel.perPage" (scrolled)="getNextPage()">

    <!-- <ng-container *ngIf='!loading'> -->
    <div *ngIf="notificationList && notificationList.length">
        <a class="notification_record" *ngFor="let item of notificationList" (click)="onNavigate(item.web_link)"
            [ngClass]="{'anchor-disable' : !item.web_link,'can_nav':item.web_link}">
            <div class="image">
                <img *ngIf="item?.image" [src]="item?.image">
                <img *ngIf="!item.image" style="width: 50% !important; height: 50% !important;"
                    src="./assets/images/icons/notification.png">
            </div>
            <div class="content">
                <div class="title">{{item?.message}}</div>
                <div class="description" *ngIf="item?.sub_title">
                    {{item?.sub_title}}
                </div>
                <div class="description" *ngIf="item?.user_name">
                    {{item?.user_name}}
                </div>
                <div class="date">
                    <span class="day">{{item.created_at |dateAgo}}</span>
                    {{item?.created_at | date :'d MMMM y - h:mm a' }}
                </div>
            </div>
        </a>
    </div>
    <!-- </ng-container> -->
    <div *ngIf='loading' class="dataLoading py-3">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <empty-state *ngIf="!notificationList?.length && !loading"
        [title]='"general.No Data Found" | translate'></empty-state>

</div>
<!-- </div> -->