<div class="sidebar-inner" [ngClass]="{open: navService.sidebarState.sidenavOpen}">
  <div class="sidebar-header">
    <img src="./assets/images/logos/logo_white.svg" alt="" class="logo-lg">
    <img src="./assets/images/logos/logo_white.svg" alt="" class="logo-sm">
  </div>
  <div class="sidebar-list-menu">
    <ul>
      <ng-container *ngFor="let item of nav">
        <li [class.active]="item.active || isActiveMenu(item.state)">
          <a routerLink="{{item.state}}" *ngIf="item.type === 'link'" routerLinkActive="active">
            <div class="item-inner">
              <div [innerHTML]="item.icon | sanitizeHtml"></div>
              <div class="item-content">
                <span [innerHTML]="item.title"></span>
                <!-- <span class="desc">{{item.description}}</span> -->
              </div>
            </div>
          </a>
          <ng-container *ngIf="item.type === 'dropDown'">
            <a (click)="selectItem(item)" routerLinkActive="active" class="dropdownLink">
              <div class="item-inner">
                <div [innerHTML]="item.icon | sanitizeHtml"></div>
                <div class="item-content">
                  <span>{{item.title}}</span>
                  <!-- <span class="desc">{{item.description}}</span> -->
                </div>
              </div>
              <i class="material-icons">expand_more</i>
            </a>
            <ul class="childNav">

              <ng-container *ngFor="let sub of item?.sub">

                <li class="nav-item">
                  <a routerLink="{{sub.state}}" routerLinkActive="active">
                    <span class="item-name">{{sub.title}}</span>
                  </a>
                </li>

              </ng-container>

            </ul>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
  <!-- <div class="sidebar-footer">
    <p>
      <b>© 2023 inndeve,</b>
      {{'general.All Rights Reserved' | translate}}
    </p>
  </div> -->
</div>
<div [ngClass]="{open: navService.sidebarState.sidenavOpen}" class="sidebar-overlay" (click)="toggelSidebar()"></div>