import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { NavigationService } from '../../../services/navigation.service';
import Swal from 'sweetalert2';
import { User } from '../../../models/auth/user';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { ListingModel } from '../../../features/companies/enum/company';
import { ClaimPointOfferComponent } from '../../../shared/claim-point-offer/claim-point-offer.component';
import { VendorOffersService } from '../../../features/Vendor-Admin/vendor-offers/services/vendor-offers.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Output() sidebarTrigger = new EventEmitter();
  currentUser!: User | null;

  userMenu = false;
  shouldShow = false;

  activeAlerts = {};

  fullName!: any;

  branchesLoading = false;
  branchesList: any = []
  vendorId!: any;

  notificationListModel: ListingModel = new ListingModel;
  totalNotifications: number = 0;
  totalUnreadNotifications: number = 0;
  notificationList: any = [];
  notificationList_opened: boolean = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialog,
    public navService: NavigationService,
    private authenticationService: AuthenticationService,
    private _dialog: MatDialog,
    private vendorOffersService: VendorOffersService
  ) {
    this.authenticationService.currentUserSubject.subscribe(user => {
      this.currentUser = user;
      // this.fullName = this.currentUser?.full_name.split(' ').slice(0,2).join(' ');
    });
  }
  getOut(context: HeaderComponent) {
    context.logout();
    Swal.fire({
      title: this.translate.instant('swalMsg.Sorry'),
      text: this.translate.instant('swalMsg.accountHasChanged'),
      icon: 'warning',
      confirmButtonText: this.translate.instant('swalMsg.Ok'),
    });
  }

  ngOnInit() {
    this.vendorId = this.authenticationService.currentUserValue?.vendor_id || '';
    this.GetNotificationsList();
    if (this.currentUser?.vendor_id) {
      this.getBranches();
    }
  }
  ngOnDestroy(): void {

  }
  // SignalR
  updateNotification(context: HeaderComponent) {
    context.openSnackBar(context.translate.instant('general.You have a new notification'));
  }
  openSnackBar(msg: string) {
    this.snackBar.open(msg, '✖', { duration: 10000, horizontalPosition: 'end', verticalPosition: 'bottom' });
  }

  // Actions
  changeLanguage() {
    if (localStorage.getItem('language') === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
  }


  OpenNotificationList() {
    const dialogRef = this._dialog.open(NotificationListComponent, {
      width: "540px",
      data: {
        list: this.notificationList,
        total: this.totalNotifications
      }
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.totalUnreadNotifications = 0;
      }
    })
  }


  GetNotificationsList() {
    let model = { ...this.notificationListModel }
    model.page += 1;
    this.navService.GetNotifiactions(model).subscribe((result) => {
      this.notificationList = result.data.list;
      this.totalNotifications = result.data.total;
      this.totalUnreadNotifications = result.data.count_unread;
    })
  }

  getBranches() {
    this.branchesLoading = true;
    let offerId = null;
    this.vendorOffersService.getBranchesForVendor(this.vendorId, offerId).subscribe((res) => {
      this.branchesLoading = false;
      this.branchesList = res.data;
      // this.branchesCount = res.data.total;
    }, (error: any) => {
      this.branchesLoading = false;
    }
    )
  }

  claimPointOffer() {
    const dialogRef = this._dialog.open(ClaimPointOfferComponent, {
      width: "540px",
      data: { branches: this.branchesList }
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        
      }
    })
  }
}
