import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.component.html',
})
export class EmptyLayoutComponent implements OnInit {
  language = localStorage.getItem('language') || 'ar';
  moduleLoading: boolean = false;

  constructor(
    public router: Router,
  ) {
    /*  router.events.subscribe((event: RouterEvent) => {
       this.navigationInterceptor(event);
     }); */
  }

  ngOnInit(): void {
  }

  // Shows and hides the moduleLoading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.moduleLoading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }

    // Set moduleLoading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.moduleLoading = false;
      }, 100);
    }
  }

  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }

}
