import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HeaderComponent } from './layouts/admin-layout/header/header.component';
import { SidebarComponent } from './layouts/admin-layout/sidebar/sidebar.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ActivatedRoute } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyProfileComponent } from './features/my-profile/my-profile.component';
import { UpdatePasswordComponent } from './features/my-profile/components/update-password/update-password.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { UpdateProfileComponent } from './features/my-profile/components/update-profile/update-profile.component';
import { NotAuthorizedComponent } from './features/pages/not-authorized/not-authorized.component';
import { ErrorComponent } from './features/pages/error/error.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { CompanyAdminProfileComponent } from './features/Company-Admin/company-admin-profile/company-admin-profile.component';
import { CompanyOffersComponent } from './features/Company-Admin/company-offers/company-offers.component';
import { RequestWithdrawalPointsComponent } from './features/Company-Admin/request-withdrawal-points/request-withdrawal-points.component';
import { UpdateCompanyColorsComponent } from './features/Company-Admin/company-admin-profile/update-company-colors/update-company-colors.component';
import { SvgCustomColorComponent } from './features/Company-Admin/company-admin-profile/update-company-colors/svg-custom-color/svg-custom-color.component';
import { SendPointsComponent } from './features/Company-Admin/send-points/send-points.component';
import { ChargeWalletComponent } from './features/Company-Admin/company-admin-profile/charge-wallet/charge-wallet.component';
import { ConfirmColorsComponent } from './features/Company-Admin/company-admin-profile/update-company-colors/confirm-colors/confirm-colors.component';
import { UpdateCompanyFilesComponent } from './features/Company-Admin/company-admin-profile/update-company-files/update-company-files.component';
import { UpdateCompanyInfoComponent } from './features/Company-Admin/company-admin-profile/update-company-info/update-company-info.component';
import { SuccessTrancactionComponent } from './features/Company-Admin/company-admin-profile/charge-wallet/success-trancaction/success-trancaction.component';
import { VendorAdminProfileComponent } from './features/Vendor-Admin/vendor-admin-profile/vendor-admin-profile.component';
import { VendorAddressesComponent } from './features/Vendor-Admin/vendor-admin-profile/components/vendor-addresses/vendor-addresses.component';
import { VendorRegisteryDataComponent } from './features/Vendor-Admin/vendor-admin-profile/components/vendor-registery-data/vendor-registery-data.component';
import { VendorContactInfoComponent } from './features/Vendor-Admin/vendor-admin-profile/components/vendor-contact-info/vendor-contact-info.component';
import { VendorCategoriesComponent } from './features/Vendor-Admin/vendor-admin-profile/components/vendor-categories/vendor-categories.component';
import { UpdateAddressFormComponent } from './features/Vendor-Admin/vendor-admin-profile/components/vendor-addresses/update-address-form/update-address-form.component';
import { UpdateSocialInfoComponent } from './features/Vendor-Admin/vendor-admin-profile/components/vendor-contact-info/update-social-info/update-social-info.component';
import { UpdateVendorCategoriesComponent } from './features/Vendor-Admin/vendor-admin-profile/components/vendor-categories/update-vendor-categories/update-vendor-categories.component';
import { NotificationListComponent } from './layouts/admin-layout/notification-list/notification-list.component';
import { DateAgoPipe } from './helpers/date-ago.pipe';
import { RequestWithdrawalPointsForVendorComponent } from './features/Vendor-Admin/request-withdrawal-points-for-vendor/request-withdrawal-points-for-vendor.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BasicLayoutComponent,
    EmptyLayoutComponent,
    AdminLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    MyProfileComponent,
    UpdatePasswordComponent,
    UpdateProfileComponent,
    NotAuthorizedComponent,
    ErrorComponent,
    CompanyAdminProfileComponent,
    AuthLayoutComponent,
    CompanyOffersComponent,
    RequestWithdrawalPointsComponent,
    UpdateCompanyColorsComponent,
    SvgCustomColorComponent,
    SendPointsComponent,
    ChargeWalletComponent,
    ConfirmColorsComponent,
    UpdateCompanyFilesComponent,
    UpdateCompanyInfoComponent,
    SuccessTrancactionComponent,
    VendorAdminProfileComponent,
    VendorAddressesComponent,
    VendorRegisteryDataComponent,
    VendorContactInfoComponent,
    VendorCategoriesComponent,
    UpdateAddressFormComponent,
    UpdateSocialInfoComponent,
    UpdateVendorCategoriesComponent,
    NotificationListComponent,
    DateAgoPipe,
    RequestWithdrawalPointsForVendorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('language') || 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      // missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['lang'] && (params['lang'] == 'ar' || 'en')) {
        if (localStorage.getItem('language') !== params['lang']) {
          translate.use(params['lang']);
          localStorage.setItem('language', params['lang']);
          location.reload();
        }
      }
    });
  }
}
