<nav class="head-nav d-flex">
    <h2>{{ "userProfile.My Profile" | translate }}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="/admin/profile">{{ "general.Home" | translate }}</a>
        </li>
    </ol>
</nav>



<div *ngIf='loading' class="dataLoading py-5">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<div *ngIf="!loading && userData?.has_changes && type != 'admin'" class="col-12 p-3 rounded mb-3"
    style="background-color: #fff3cd;">
    {{"users.UserVerifyChanges"|translate}}
</div>
<div *ngIf="!loading" class="profile_card container-fluid">
    <div class="row h-100">

        <div class="img_side col-12 col-md-3 col-xl-3">
            <!-- <img src="/assets/images/profile_bg.svg" alt="prsofile_Image" class="cover"> -->
            <img *ngIf="userData?.image" [src]="userData?.image" (error)="userData.image = ''" alt="profile_Image"
                class="profile_img">
            <div *ngIf="!userData?.image" class="profile_img">
                <span>{{userData?.full_name|shortName}}</span>
            </div>
        </div>
        <div class="data_side col-12  col-md-9 col-xl-9 bg-dager">
            <div class="actions" *ngIf="userData.user_type !='admin'">
                <button mat-button class="btn-green-light" (click)="OnUpdatePassword()">
                    <img src="/assets/images/icons/key.svg" alt="icon">
                    <span>{{"userProfile.Change the Password"|translate}}</span>
                </button>
                <button mat-button class="btn-primary" (click)="OnUpdateProfile()">
                    <img src="/assets/images/icons/edit_pen.svg" alt="icon">
                    <span>{{"userProfile.update my account"|translate}}</span>
                </button>
            </div>
            <div class="info">
                <h3 [matTooltip]="userData?.full_name"> {{userData?.full_name |titlecase}}</h3>
                <p *ngIf="userData?.role"> {{userData?.role.name}}</p>
                <div class="items">
                    <div class="item">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 18 18">
                                <path id="ic_email"
                                    d="M89-862a8.765,8.765,0,0,1-3.51-.709,9.089,9.089,0,0,1-2.858-1.924,9.09,9.09,0,0,1-1.924-2.857A8.764,8.764,0,0,1,80-871a8.764,8.764,0,0,1,.709-3.51,9.088,9.088,0,0,1,1.924-2.857,9.088,9.088,0,0,1,2.858-1.924A8.763,8.763,0,0,1,89-880a8.763,8.763,0,0,1,3.51.709,9.088,9.088,0,0,1,2.857,1.924,9.087,9.087,0,0,1,1.924,2.857A8.764,8.764,0,0,1,98-871v1.305a3.119,3.119,0,0,1-.911,2.261,3.006,3.006,0,0,1-2.239.934,3.366,3.366,0,0,1-1.485-.337,3.172,3.172,0,0,1-1.17-.967,4.331,4.331,0,0,1-1.474.979A4.62,4.62,0,0,1,89-866.5a4.337,4.337,0,0,1-3.184-1.316A4.337,4.337,0,0,1,84.5-871a4.337,4.337,0,0,1,1.316-3.184A4.337,4.337,0,0,1,89-875.5a4.337,4.337,0,0,1,3.184,1.316A4.337,4.337,0,0,1,93.5-871v1.305a1.388,1.388,0,0,0,.382.99,1.273,1.273,0,0,0,.967.405,1.273,1.273,0,0,0,.967-.405,1.388,1.388,0,0,0,.382-.99V-871a6.949,6.949,0,0,0-2.092-5.107A6.949,6.949,0,0,0,89-878.2a6.949,6.949,0,0,0-5.108,2.092A6.949,6.949,0,0,0,81.8-871a6.949,6.949,0,0,0,2.092,5.107A6.949,6.949,0,0,0,89-863.8h4.5v1.8Zm0-6.3a2.6,2.6,0,0,0,1.912-.787A2.6,2.6,0,0,0,91.7-871a2.6,2.6,0,0,0-.787-1.912A2.6,2.6,0,0,0,89-873.7a2.6,2.6,0,0,0-1.912.787A2.6,2.6,0,0,0,86.3-871a2.6,2.6,0,0,0,.788,1.913A2.6,2.6,0,0,0,89-868.3Z"
                                    transform="translate(-80 880)" fill="#999aab" />
                            </svg>
                        </div>
                        <div class="text">
                            <div class="title">{{"userProfile.Email"|translate}}</div>
                            <a [matTooltip]="userData?.email" class="lable"
                                href="mailto:{{userData?.email}}">{{userData?.email}}</a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 13 20">
                                <path id="ic_phone"
                                    d="M200.015-900.5a1.468,1.468,0,0,1-1.064-.443,1.419,1.419,0,0,1-.451-1.047V-919.01a1.42,1.42,0,0,1,.451-1.047,1.468,1.468,0,0,1,1.064-.443h9.969a1.468,1.468,0,0,1,1.064.443,1.42,1.42,0,0,1,.451,1.047v17.021a1.42,1.42,0,0,1-.451,1.047,1.468,1.468,0,0,1-1.064.443Zm0-3.5h9.969v-13h-9.969Z"
                                    transform="translate(-198.5 920.5)" fill="#999aab" />
                            </svg>

                        </div>
                        <div class="text">
                            <div class="title"> {{"userProfile.Mobile"|translate}}</div>
                            <div class="lable always-ltr">+966 {{userData?.phone_number}}</div>
                        </div>
                    </div>
                    <div class="item" *ngIf="userData?.job_title">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 19">
                                <path id="position_ic"
                                    d="M82-861a1.926,1.926,0,0,1-1.412-.588A1.926,1.926,0,0,1,80-863v-11a1.926,1.926,0,0,1,.588-1.412A1.926,1.926,0,0,1,82-876h4v-2a1.926,1.926,0,0,1,.588-1.412A1.926,1.926,0,0,1,88-880h4a1.926,1.926,0,0,1,1.412.588A1.926,1.926,0,0,1,94-878v2h4a1.926,1.926,0,0,1,1.412.588A1.926,1.926,0,0,1,100-874v11a1.926,1.926,0,0,1-.588,1.412A1.926,1.926,0,0,1,98-861Zm6-15h4v-2H88Z"
                                    transform="translate(-80 880)" fill="#999aab" />
                            </svg>
                        </div>
                        <div class="text">
                            <div class="title"> {{"userProfile.Job title"|translate}}</div>
                            <div class="lable" [matTooltip]="userData?.job_title">{{userData?.job_title}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>