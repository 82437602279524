<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{ 'vendorAdminProfile.Update Vendor Address' | translate }}
    </div>
    <form [formGroup]="formGroup">
        <div class="dialog_content pb-0" mat-dialog-content>


            <div class="title">{{"vendors.Vendor Address"|translate}}</div>
            <div class="fields-group mb-3" *ngIf="false">
                <div class="ng-select-field">
                    <ng-select ngSelectMat appearance="fill" [class.ng-invalid]="hasError('city_id','required')"
                        formControlName="city_id" [items]="CitesList" [virtualScroll]="true" [loading]="loadingCities"
                        bindLabel="name" bindValue="id" [placeholder]='"companies.The City" | translate'
                        [notFoundText]='"general.No Data Found" | translate'
                        [loadingText]='"forms.Loading..." | translate' (search)="dropSearch($event)" (clear)="OnClear()"
                        dropdownPosition="bottom" [clearable]="false" [disabled]="true"
                        (change)="citesModal.search=''; dropSearch($event)">
                        <!-- <ng-template ng-header-tmp *ngIf="CitesList?.length && !loadingCities">
                            <small class="form-text text-muted">{{'forms.Loaded' | translate}}
                                {{CitesList.length}} {{'forms.of' | translate}} {{citiesCount}}</small>
                        </ng-template> -->
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                    <mat-error class="has-error" *ngIf="hasError('city_id','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </div>
            </div>
            <div class="fields-group mb-3">
                <!-- <mat-form-field class="with-suffix-padding">
                    <mat-label>{{ "vendorAdminProfile.Insert Location" | translate }}</mat-label>
                    <input matInput [(ngModel)]="SearchMap" [ngModelOptions]="{standalone:true}">
                    <span matSuffix>
                        <svg id="Trailing_Icons" data-name="Trailing Icons" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24">
                            <g id="search">
                                <rect id="container" width="24" height="24" fill="none" />
                                <path id="search_FILL0_wght400_GRAD0_opsz24"
                                    d="M136.6-822l-6.3-6.3a5.96,5.96,0,0,1-1.725.95,6.1,6.1,0,0,1-2.075.35,6.274,6.274,0,0,1-4.613-1.888A6.274,6.274,0,0,1,120-833.5a6.274,6.274,0,0,1,1.887-4.612A6.274,6.274,0,0,1,126.5-840a6.274,6.274,0,0,1,4.613,1.888A6.274,6.274,0,0,1,133-833.5a6.1,6.1,0,0,1-.35,2.075,5.959,5.959,0,0,1-.95,1.725l6.3,6.3Zm-10.1-7a4.34,4.34,0,0,0,3.188-1.312A4.34,4.34,0,0,0,131-833.5a4.34,4.34,0,0,0-1.312-3.187A4.34,4.34,0,0,0,126.5-838a4.34,4.34,0,0,0-3.187,1.313A4.34,4.34,0,0,0,122-833.5a4.34,4.34,0,0,0,1.313,3.188A4.34,4.34,0,0,0,126.5-829Z"
                                    transform="translate(-117 843)" fill="#999aab" stroke="rgba(0,0,0,0)"
                                    stroke-miterlimit="10" stroke-width="1" />
                            </g>
                        </svg>

                    </span>
                </mat-form-field> -->
                <mat-form-field class="example-full-width" [class.loading-field]="MapSearchLoading">
                    <mat-label>{{ "vendors.Insert Location" | translate }}</mat-label>
                    <input type="text" matInput [(ngModel)]="SearchMap" (ngModelChange)="SearchInMap($event)"
                        [ngModelOptions]="{standalone:true}" [matAutocomplete]="auto">
                    <span matSuffix class="grey-color px-2 cursor_pointer" *ngIf="SearchMap" (click)="SearchMap=''">
                        <mat-icon>close</mat-icon>
                    </span>
                    <span matSuffix>
                        <svg id="Trailing_Icons" data-name="Trailing Icons" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24">
                            <g id="search">
                                <rect id="container" width="24" height="24" fill="none" />
                                <path id="search_FILL0_wght400_GRAD0_opsz24"
                                    d="M136.6-822l-6.3-6.3a5.96,5.96,0,0,1-1.725.95,6.1,6.1,0,0,1-2.075.35,6.274,6.274,0,0,1-4.613-1.888A6.274,6.274,0,0,1,120-833.5a6.274,6.274,0,0,1,1.887-4.612A6.274,6.274,0,0,1,126.5-840a6.274,6.274,0,0,1,4.613,1.888A6.274,6.274,0,0,1,133-833.5a6.1,6.1,0,0,1-.35,2.075,5.959,5.959,0,0,1-.95,1.725l6.3,6.3Zm-10.1-7a4.34,4.34,0,0,0,3.188-1.312A4.34,4.34,0,0,0,131-833.5a4.34,4.34,0,0,0-1.312-3.187A4.34,4.34,0,0,0,126.5-838a4.34,4.34,0,0,0-3.187,1.313A4.34,4.34,0,0,0,122-833.5a4.34,4.34,0,0,0,1.313,3.188A4.34,4.34,0,0,0,126.5-829Z"
                                    transform="translate(-117 843)" fill="#999aab" stroke="rgba(0,0,0,0)"
                                    stroke-miterlimit="10" stroke-width="1" />
                            </g>
                        </svg>

                    </span>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option (click)="OnSelectInSearch(option)" *ngFor="let option of MapSearchList"
                            [value]="option.display_name">
                            {{option.display_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <!-- Map Will be here -->
            <div class="mb-3">
                <div height="250px" class="w-100">
                    <google-map id="customMap7" width="100%" class="w-100 h-100" [options]="mapOptions"
                        [center]="MapCenter" [zoom]="MapZoom" (mapClick)="pickMap($event)">
                        <map-marker *ngIf="marker" [options]="marker"></map-marker>
                    </google-map>
                </div>
            </div>

            <div class="fields-group mb-3">
                <mat-form-field class="arabic-form-field">
                    <mat-label>العنوان التفصيلي (باللغة العربية)</mat-label>
                    <textarea matInput rows="5" maxlength="100" formControlName="address_ar"></textarea>
                    <mat-hint class="pt-1">{{"general.optionalAR"|translate}}</mat-hint>
                    <mat-error *ngIf="hasError('address_ar', 'pattern')">
                        {{ 'forms.Enter address (in Arabic)' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field class="english-form-field">
                    <mat-label>Street Address (in English)</mat-label>
                    <textarea matInput rows="5" maxlength="100" formControlName="address_en"></textarea>
                    <mat-hint class="pt-1">{{"general.optionalEn"|translate}}</mat-hint>
                    <mat-error *ngIf="hasError('address_en', 'pattern')">
                        {{ 'forms.Enter address (in English)' |translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="dialog_actions" mat-dialog-actions>
        <button mat-button (click)="openConfirmDialog()" [disabled]="loading"
            class="btn-secondary">{{"general.Cancel"|translate}}</button>
        <button mat-button class="btn-primary" [disabled]="loading" [class.loading]="loading" (click)="saveRecord()">
            {{ 'vendorAdminProfile.Update' | translate }}</button>
    </div>
</div>