import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DirtyComponent } from '../../../../../../helpers/CanDeactivate/dirty-component';
import { VendorProfileService } from '../../../services/vendor-profile.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Patterns } from '../../../../../../helpers/patterns';
import { Subscription } from 'rxjs';
import { VendorsService } from '../../../../../vendors/services/vendors.service';
import Swal from 'sweetalert2';

export interface DialogData {
  details: any
}

@Component({
  selector: 'app-update-address-form',
  templateUrl: './update-address-form.component.html',
  styles: ``
})
export class UpdateAddressFormComponent extends DirtyComponent {
  loading = false;
  dataLoading = false;
  override formGroup!: FormGroup;
  isSubmitted = false;
  details!: any;

  SearchMap: any
  display: any;


  // City Select 
  loadingCities: boolean = false;
  citesModal = { perPage: 200, page: 1, search: '' }
  CitesList: any = []
  citiesCount: number = 0;
  City_Subscriptions: Subscription[] = []


  mapOptions!: google.maps.MapOptions
  marker!: google.maps.MarkerOptions

  MapCenter!: any
  MapZoom!: any
  MapSearchList: any[] = [];
  MapSearchLoading: boolean = false;
  MapSearchSubscriptions: Subscription[] = [];

  constructor(
    override dialogRef: MatDialogRef<UpdateAddressFormComponent>,
    translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: VendorProfileService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _vendorService: VendorsService) {
    super(translate);
  }

  ngOnInit(): void {
    this.details = this.data?.details;
    this.marker = {
      position: {
        lat: Number(this.details.map_lat),
        lng: Number(this.details.map_lang)
      }
    }

    this.mapOptions = {
      mapId: 'customMap7',
      scrollwheel: true,
      disableDoubleClickZoom: false,
      mapTypeId: 'roadmap',
      zoomControl: true,
      fullscreenControl: true,
      mapTypeControl: false,
    };
    this.MapCenter = {
      lat: this.data ? Number(this.details?.map_lat) : 24.774265,
      lng: this.data ? Number(this.details?.map_lang) : 46.738586
    }
    this.MapZoom = !this.data ? 7 : null;

    this.buildForm();
    this.GetCities();
    this.formGroup.controls['city_id'].disable();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      city_id: [
        this.details?.city_id, [
          Validators.required
        ]
      ],
      address_ar: [
        this.details?.address_ar, [
          Validators.maxLength(100),
          Validators.pattern(Patterns.arTxtWithSpecial)
        ]
      ],
      address_en: [
        this.details?.address_en, [
          Validators.maxLength(100),
          Validators.pattern(Patterns.enTxtWithSpecial)
        ]
      ],
      map_lat: [
        this.details.map_lat,
        [
          Validators.required
        ]
      ], // Jaddah Latitude
      map_lang: [
        this.details.map_lang,
        [
          Validators.required
        ]
      ],
    })
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  GetCities() {
    this.City_Subscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.loadingCities = true;
    this.City_Subscriptions.push(this._vendorService.GetCities_Filter(this.citesModal).subscribe({
      next: (res: any) => {
        this.loadingCities = false;
        this.CitesList = this.CitesList.concat(res.data.list);
        this.citiesCount = res.data.total;
      },
      error: (error: any) => {
        this.loadingCities = false;
      }
    }))
  }

  dropSearch(data: any) {
    this.CitesList = [];
    this.citesModal.search = data?.term || '';
    this.GetCities();
  }

  OnClear() {
    this.formGroup.patchValue({ city_id: null })
    this.citesModal.search = ''
    this.CitesList = [];
    this.GetCities();
  }

  // Google Maps controls
  pickMap(event: google.maps.MapMouseEvent) {
    // if (event.latLng != null) this.center = (event.latLng.toJSON());
    this.formGroup.patchValue({
      map_lat: event.latLng?.lat(),
      map_lang: event.latLng?.lng()
    })
    this.marker = {
      position: {
        lat: event.latLng?.lat() || 0,
        lng: event.latLng?.lng() || 0
      }
    }

    console.log({
      map_lat: event.latLng?.lat(),
      map_lang: event.latLng?.lng()
    });
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }


  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      this.service.updateVendorAddress(this.details.id, this.formGroup.value).subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.updatedsuccessfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
      });
    }
  }


  SearchInMap(text: string) {
    // console.log(text);
    if (text.length) {
      this.MapSearchSubscriptions.forEach(element => {
        element.unsubscribe();
      });
      this.MapSearchLoading = true;

      this.MapSearchSubscriptions.push(this._vendorService.SearchInMap(text).subscribe({
        next: (res: any) => {
          this.MapSearchLoading = false;
          this.MapSearchList = res;
        },
        error: (error: any) => {
          this.MapSearchLoading = false;
        }
      }))
    }
  }

  OnSelectInSearch(event: any) {
    console.log(event);
    this.marker = {
      position: {
        lat: Number(event.lat),
        lng: Number(event.lon)
      }
    }
    this.MapCenter = {
      lat: Number(event.lat),
      lng: Number(event.lon)
    }

    this.formGroup.patchValue({
      map_lat: Number(event.lat),
      map_lang: Number(event.lon)
    })
    this.MapZoom = 12;
  }

  ngOnDestroy(): void {
    this.MapSearchSubscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

}
