import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from '../../../../helpers/config.service';
import { DirtyComponent } from '../../../../helpers/CanDeactivate/dirty-component';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { CompanyProfileService } from '../services/company-profile.service';

@Component({
  selector: 'app-charge-wallet',
  templateUrl: './charge-wallet.component.html',
  styles: ``
})
export class ChargeWalletComponent extends DirtyComponent implements OnInit {
  loading = false;
  dataloading = false;
  override formGroup!: FormGroup<any>;
  isSubmited: boolean = false;
  PaymentMethods: any[] = [];
  redirectLink!: string
  SelectedPaymentProvider: any
  constructor(override dialogRef: MatDialogRef<ChargeWalletComponent>,
    private _config: ConfigService,
    override translate: TranslateService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _companyProfileSErvice: CompanyProfileService) {
    super(translate)
  }


  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      amount: [null, [Validators.required, Validators.min(1)]],
      payment_provider: [null, [Validators.required]]
    })

    console.log(this.formGroup);
    this.getPaymentProviders()
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  saveRecord() {
    console.log(this.formGroup);

    this.isSubmited = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let model = { ... this.formGroup.value }
      this.SelectedPaymentProvider = model.payment_provider
      this._companyProfileSErvice.ChargeWallet(model).subscribe({
        next: (res: any) => {
          this.redirectLink =
            res.data.paymentConfirmationDomain +
            res.data.paymentConfirmationToken;
          console.log(this.redirectLink);
          this.redirectLink = res.data.paymentConfirmationDomain + res.data.paymentConfirmationToken
          console.log(this.loadJsScript(res.data.paymentGatewayURL));
          this.loadJsScript(res.data.paymentGatewayURL);
          this.loading = false;
          this.formGroup.get('amount')?.clearValidators()
          this.formGroup.get('payment_provider')?.clearValidators()
          this.formGroup.reset();
        },
        error: (error: any) => {
          this.loading = false;
        }
      })
    }
  }


  getPaymentProviders() {
    this.dataloading = true;

    this._companyProfileSErvice.GetPaymentProviders().subscribe({
      next: (res: any) => {
        this.dataloading = false;
        this.PaymentMethods = res.data;
      },
      error: (error: any) => {
        this.dataloading = false;
      }
    })
  }

  public loadJsScript(src: string): HTMLScriptElement {
    const FormAmountScript = document.getElementById('WalletAmountForm');
    FormAmountScript?.remove();
    const oldScript = document.getElementById('e-payment-script');
    const script = this.renderer.createElement('script');
    script.id = 'e-payment-script';
    script.src = src;

    if (oldScript) {
      this.renderer.removeChild(this.document.body, oldScript);
    }
    this.renderer.appendChild(this.document.body, script);
    return script;
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    const oldScript = document.getElementById('e-payment-script')?.remove();


  }
}
