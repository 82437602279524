import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styles: ``
})
export class AuthLayoutComponent implements OnInit {
  language = localStorage.getItem('language') || 'ar';
  moduleLoading: boolean = false;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
  }


  changeLanguage() {
    let params = JSON.parse(JSON.stringify(this.activatedRoute.snapshot.queryParams))
    let lang = this.language == 'ar' ? 'en' : 'ar'
    params['lang'] = lang
    localStorage.setItem('language', lang);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

}
