<nav class="head-nav d-flex">
    <h2>{{ "vendorAdminProfile.Vendor profile" | translate }}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="/admin/profile">{{ "general.Home" | translate }}</a>
        </li>
    </ol>
</nav>

<div *ngIf='dataLoading' class="dataLoading py-5">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf="!dataLoading">
    <div class="company_Offer_Profile company-admin">
        <div class="row">
            <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                <div class="top-profile-card">
                    <div class="top-profile-card-content">
                        <div class="logo-card">
                            <img *ngIf="vendorDetails?.logo" [src]="vendorDetails?.logo"
                                (error)="vendorDetails.logo=''" alt="">
                            <span *ngIf="!vendorDetails?.logo">{{ vendorDetails.name | shortName }}</span>
                        </div>
                        <div class="top-info">
                            <div class="top-profile-card-header">
                                <div class="badges d-flex">

                                    <ng-container *ngIf="vendorDetails?.status == 'active'">
                                        <div class="status-badge green">
                                            {{'users.Active Account' | translate}}
                                            <img src="./assets/images/icons/Verified_active_account_ic.svg" alt="">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="vendorDetails?.status == 'suspend'">
                                        <div class="status-badge red">
                                            {{'users.Suspended Account' | translate}}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.998" height="16.999"
                                                viewBox="0 0 19.998 16.999">
                                                <path id="suspend_ic"
                                                    d="M20071,10694a5,5,0,1,1,5,5A5,5,0,0,1,20071,10694Zm1,0a3.993,3.993,0,0,0,6.451,3.155l-5.6-5.6A3.918,3.918,0,0,0,20072,10694Zm1.553-3.154,5.6,5.6a3.934,3.934,0,0,0,.846-2.449,4,4,0,0,0-4-4A3.938,3.938,0,0,0,20073.551,10690.844ZM20061,10698v-2.656a2.918,2.918,0,0,1,1.387-2.524A12.875,12.875,0,0,1,20069,10691c.227,0,.449.01.67.021a6.972,6.972,0,0,0,.59,6.979Zm4-12a4,4,0,1,1,4,4A4.005,4.005,0,0,1,20065,10686Z"
                                                    transform="translate(-20061.002 -10681.999)" fill="#999" />
                                            </svg>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="vendorDetails?.status == 'not_verified'">
                                        <div class="status-badge yellow">
                                            {{'companyProfile.Not verified Account' | translate}}
                                            <img src="./assets/images/icons/not_verified_ic.svg" alt="">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="vendorDetails?.status == 'expired'">
                                        <div class="status-badge red">
                                            {{'users.Expired Account' | translate}}
                                        </div>
                                    </ng-container>
                                    <!-- <div class="status-badge">
                                        {{'companyProfile.Subscription Package' | translate}}
                                        <b>{{vendorDetails?.package?.name_en}} -
                                            {{vendorDetails?.package?.name_ar}}</b>
                                    </div> -->
                                </div>
                            </div>

                            <div class="top-profile-card-details">
                                <h3>{{vendorDetails?.name_ar}}</h3>
                                <h4>{{vendorDetails?.name_en}}</h4>

                                <div class="d-flex mt-3 align-items-baseline">
                                    <p>
                                        <span>{{ 'companyAdminProfile.Joining Date' | translate }}:</span>
                                        <b>{{vendorDetails?.created_at| date :'d MMMM y - h:mm a'}}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <div class="points-card vendor">
                    <div class="img-card pb-3">
                        <!-- <img src="/assets/images/coins.svg" alt="coins image" class="mb-3 coins_img"> -->
                        <div class="points-info">
                            <h6>{{ 'companyAdminProfile.Your current balance' | translate }}</h6>
                            <h2>{{vendorDetails?.balance?.balance | number}} <span>{{ 'companies.point' | translate }}</span>
                            </h2>
                            
                            <p *ngIf="vendorDetails?.balance?.Last_transaction_date">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                    <path id="event_FILL1_wght400_GRAD0_opsz24"
                                        d="M128.944-867.2a1.851,1.851,0,0,1-1.381-.58A1.96,1.96,0,0,1,127-869.2a1.959,1.959,0,0,1,.564-1.42,1.851,1.851,0,0,1,1.381-.58,1.851,1.851,0,0,1,1.381.58,1.959,1.959,0,0,1,.564,1.42,1.96,1.96,0,0,1-.564,1.42A1.851,1.851,0,0,1,128.944-867.2Zm-7.389,3.2a1.477,1.477,0,0,1-1.1-.47A1.563,1.563,0,0,1,120-865.6v-11.2a1.563,1.563,0,0,1,.457-1.13,1.478,1.478,0,0,1,1.1-.47h.778v-.8a.785.785,0,0,1,.224-.57.741.741,0,0,1,.554-.23.741.741,0,0,1,.554.23.786.786,0,0,1,.224.57v.8h6.222v-.8a.785.785,0,0,1,.224-.57.741.741,0,0,1,.554-.23.741.741,0,0,1,.554.23.785.785,0,0,1,.224.57v.8h.778a1.478,1.478,0,0,1,1.1.47A1.563,1.563,0,0,1,134-876.8v11.2a1.563,1.563,0,0,1-.457,1.13,1.477,1.477,0,0,1-1.1.47Zm0-1.6h10.889v-8H121.556Z"
                                        transform="translate(-120 880)" fill="#b0b0be" />
                                </svg>

                                <span>{{ 'companyProfile.Date of last transaction' | translate }}</span>
                                <b>{{vendorDetails?.balance?.Last_transaction_date | date :'d MMMM y - h:mm a' }}</b>
                            </p>

                            <button mat-button *ngIf="permissions?.can_withdrawal_request"
                            class="btn-light m-3" (click)="requestWithdrawalRequest()">
                                {{"companyAdminProfile.Submit a withdrawal request" | translate}}
                            </button>

                            <a (click)="selectedVendorId = vendorDetails.id" *ngIf="permissions?.can_show_transactions"
                                class="font-14 fontRegular white-text d-block m-auto">
                                {{ 'companyProfile.Transaction history on the wallet' | translate }}
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <path id="ic_arrow_link"
                                        d="M213.88-756.648l9.444,9.432a.633.633,0,0,0,.474.216.671.671,0,0,0,.486-.216.671.671,0,0,0,.216-.486.672.672,0,0,0-.216-.486l-9.432-9.432h8.424a.661.661,0,0,0,.489-.2.669.669,0,0,0,.195-.492.676.676,0,0,0-.195-.494.658.658,0,0,0-.489-.2H213.2a.673.673,0,0,0-.495.2.673.673,0,0,0-.2.495v10.08a.651.651,0,0,0,.2.489.69.69,0,0,0,.5.195.656.656,0,0,0,.488-.195.669.669,0,0,0,.192-.489Z"
                                        transform="translate(-212.5 759)" fill="#fff" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="vertical-tabs mb-5">
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path id="ic_vendors_categories" d="M2162.908-82.376a4.835,4.835,0,0,1-.794-1.029A6,6,0,0,0,2167-89.3a6.054,6.054,0,0,0-.1-1.1,4.555,4.555,0,0,1,2.719,1.338A4.559,4.559,0,0,1,2171-85.718a4.56,4.56,0,0,1-1.381,3.342A4.6,4.6,0,0,1,2166.263-81,4.6,4.6,0,0,1,2162.908-82.376Zm-10.855.852a1.027,1.027,0,0,1-.75-.3,1.021,1.021,0,0,1-.3-.748v-6.29a1.016,1.016,0,0,1,.3-.747,1.02,1.02,0,0,1,.75-.3h2.978c-.02.2-.031.4-.031.612a6.015,6.015,0,0,0,4.421,5.789v.938a1.022,1.022,0,0,1-.3.748,1.026,1.026,0,0,1-.75.3ZM2156-89.3a5.005,5.005,0,0,1,5-5,5.006,5.006,0,0,1,5,5,5.006,5.006,0,0,1-5,5A5.005,5.005,0,0,1,2156-89.3Zm6.666,3.333h.834a.806.806,0,0,0,.588-.244.808.808,0,0,0,.245-.59v-.333a1.254,1.254,0,0,0-.177-.652,1.174,1.174,0,0,0-.489-.453,4.556,4.556,0,0,0-.49-.219c-.16-.059-.327-.118-.511-.176Zm-3.844-2.489a4.441,4.441,0,0,0-.49.219,1.171,1.171,0,0,0-.49.453,1.263,1.263,0,0,0-.177.652v.333a.811.811,0,0,0,.244.59.809.809,0,0,0,.589.244h.833v-2.666C2159.148-88.574,2158.982-88.516,2158.822-88.457Zm1.76-.411c-.138.01-.278.026-.417.047v1.4h1.667v-1.4c-.135-.02-.275-.037-.416-.047s-.279-.016-.419-.016S2160.721-88.879,2160.582-88.868Zm-.76-3.276a1.612,1.612,0,0,0-.49,1.176,1.614,1.614,0,0,0,.49,1.178,1.618,1.618,0,0,0,1.176.49,1.62,1.62,0,0,0,1.179-.49,1.614,1.614,0,0,0,.489-1.178,1.611,1.611,0,0,0-.489-1.176,1.616,1.616,0,0,0-1.179-.49A1.615,1.615,0,0,0,2159.822-92.144Zm-3.792-.516-.031-.018a1.042,1.042,0,0,1-.368-.38,1.07,1.07,0,0,1-.131-.525,1.069,1.069,0,0,1,.157-.551l3.921-6.369a.96.96,0,0,1,.4-.38,1.1,1.1,0,0,1,.5-.118,1.1,1.1,0,0,1,.5.118.966.966,0,0,1,.394.38l3.921,6.369a1.06,1.06,0,0,1,.157.551,1.142,1.142,0,0,1-.022.232A6.016,6.016,0,0,0,2161-95.3a6,6,0,0,0-4.968,2.64h0Z" transform="translate(-2151 101)" fill="#287676"/>
                                  </svg>                                  
                                {{ 'vendorProfile.Vendors Categories' | translate }}
                            </ng-template>
                            <div class="card">
                                <div class="card-head">
                                    <div class="card-title">
                                        <h4>{{ 'vendorProfile.Vendors Categories' | translate }}</h4>
                                    </div>
                                    <div class="card-actions">
                                        <button mat-icon-button (click)="updateVendorCategories()"
                                            [matTooltip]="'general.Edit' | translate">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <app-vendor-categories [vendorDetails]="vendorDetails"></app-vendor-categories>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="20" viewBox="0 0 16.001 20">
                                    <path id="ic_vendor_address" d="M10898.023-775a1.33,1.33,0,0,1-.937-.386c-2.13-2.1-7.086-7.433-7.086-11.5a8.069,8.069,0,0,1,8-8.118,8.069,8.069,0,0,1,8,8.118c0,4.364-4.94,9.506-7.064,11.515A1.317,1.317,0,0,1,10898.023-775Zm.848-11.225h1.743v2.187a.432.432,0,0,0,.126.312.418.418,0,0,0,.312.126.412.412,0,0,0,.31-.126.422.422,0,0,0,.126-.312v-2.187h.077a.422.422,0,0,0,.336-.159.409.409,0,0,0,.088-.367l-.436-2.187a.426.426,0,0,0-.153-.252.413.413,0,0,0-.271-.1h-6.257a.421.421,0,0,0-.273.1.428.428,0,0,0-.151.252l-.437,2.187a.418.418,0,0,0,.088.367.425.425,0,0,0,.339.159h.077v2.187a.431.431,0,0,0,.123.312.418.418,0,0,0,.312.126h3.487a.412.412,0,0,0,.31-.126.422.422,0,0,0,.126-.312v-2.186Zm-3.923-4.377a.436.436,0,0,0-.312.126.431.431,0,0,0-.123.312.431.431,0,0,0,.123.312.42.42,0,0,0,.312.126h6.1a.414.414,0,0,0,.31-.126.423.423,0,0,0,.126-.312.423.423,0,0,0-.126-.312.423.423,0,0,0-.31-.126Zm3.052,6.125h-2.616v-1.749H10898v1.748Z" transform="translate(-10890 795)" fill="#287676"/>
                                  </svg>                                                                  
                                {{ 'vendorAdminProfile.Address' | translate }}
                            </ng-template>
                            <div class="card">
                                <div class="card-head">
                                    <div class="card-title">
                                        <h4>{{ 'vendorAdminProfile.Address' | translate }}</h4>
                                    </div>
                                    <div class="card-actions">
                                        <button mat-icon-button (click)="updateVendorAddress()"
                                            [matTooltip]="'general.Edit' | translate">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <app-vendor-addresses [vendorDetails]="vendorDetails"></app-vendor-addresses>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg id="Icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g id="ic_tax_and_license_naumber">
                                      <rect id="Container" width="24" height="24" fill="none"/>
                                      <path id="ic_tax_and_license_naumber-2" data-name="ic_tax_and_license_naumber" d="M134.275-824.875l-.425-.425a.734.734,0,0,0-.538-.225.734.734,0,0,0-.538.225.719.719,0,0,0-.225.525.72.72,0,0,0,.225.525l.975.975a.719.719,0,0,0,.525.225.719.719,0,0,0,.525-.225l2.425-2.375a.734.734,0,0,0,.225-.537.734.734,0,0,0-.225-.537.734.734,0,0,0-.538-.225.734.734,0,0,0-.538.225ZM134-834a.968.968,0,0,0,.712-.287A.968.968,0,0,0,135-835a.968.968,0,0,0-.288-.713A.968.968,0,0,0,134-836H124a.968.968,0,0,0-.713.287A.968.968,0,0,0,123-835a.968.968,0,0,0,.287.713A.968.968,0,0,0,124-834Zm1,14a4.819,4.819,0,0,1-3.538-1.463A4.819,4.819,0,0,1,130-825a4.819,4.819,0,0,1,1.462-3.537A4.819,4.819,0,0,1,135-830a4.819,4.819,0,0,1,3.538,1.463A4.819,4.819,0,0,1,140-825a4.819,4.819,0,0,1-1.462,3.537A4.819,4.819,0,0,1,135-820Zm-13-20h14a1.926,1.926,0,0,1,1.413.588A1.926,1.926,0,0,1,138-838v6.675a6.1,6.1,0,0,0-1.462-.513A7.356,7.356,0,0,0,135-832H124a.968.968,0,0,0-.713.287A.968.968,0,0,0,123-831a.968.968,0,0,0,.287.713A.968.968,0,0,0,124-830h6.1a7.682,7.682,0,0,0-.788.925,6.9,6.9,0,0,0-.637,1.075H124a.968.968,0,0,0-.713.287A.968.968,0,0,0,123-827a.968.968,0,0,0,.287.713A.968.968,0,0,0,124-826h4.075a3.4,3.4,0,0,0-.062.487q-.012.237-.012.513a6.994,6.994,0,0,0,.288,2.013,6.962,6.962,0,0,0,.862,1.837.424.424,0,0,1-.262-.037.8.8,0,0,1-.238-.162l-.8-.8a.48.48,0,0,0-.35-.15.48.48,0,0,0-.35.15l-.8.8a.48.48,0,0,1-.35.15.48.48,0,0,1-.35-.15l-.8-.8a.48.48,0,0,0-.35-.15.48.48,0,0,0-.35.15l-.8.8a.48.48,0,0,1-.35.15.48.48,0,0,1-.35-.15l-.8-.8a.48.48,0,0,0-.35-.15.48.48,0,0,0-.35.15l-.8.8-.35.225V-838a1.926,1.926,0,0,1,.588-1.412A1.926,1.926,0,0,1,122-840Z" transform="translate(-118 842.003)" fill="#287676"/>
                                    </g>
                                  </svg>
                                {{ 'companyAdminProfile.Commercial and tax registry data' | translate }}
                            </ng-template>
                            <div class="card">
                                <div class="card-head">
                                    <div class="card-title">
                                        <h4>{{ 'companyAdminProfile.Commercial and tax registry data' | translate }}</h4>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <app-vendor-registery-data [vendorDetails]="vendorDetails"></app-vendor-registery-data>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                                    <path id="ic_contacts" d="M50.909-788.194a10.03,10.03,0,0,1,2.011-.713,9.07,9.07,0,0,1,2.08-.237,9.374,9.374,0,0,1,1.6.136,10.348,10.348,0,0,1,1.58.407v-8.956a7.9,7.9,0,0,0-1.557-.475A8.416,8.416,0,0,0,55-798.191a8.377,8.377,0,0,0-2.114.271,7.8,7.8,0,0,0-1.977.814ZM50-786a2.233,2.233,0,0,1-.6-.079,2.549,2.549,0,0,1-.534-.215,7.457,7.457,0,0,0-1.864-.78,7.743,7.743,0,0,0-2-.26,7.153,7.153,0,0,0-1.875.249,8.248,8.248,0,0,0-1.761.7.879.879,0,0,1-.92-.023A.873.873,0,0,1,40-787.2v-10.9a.968.968,0,0,1,.125-.475.783.783,0,0,1,.375-.339,11.275,11.275,0,0,1,2.193-.8A9.428,9.428,0,0,1,45-800a9.89,9.89,0,0,1,2.58.339A9.825,9.825,0,0,1,50-798.643a9.825,9.825,0,0,1,2.42-1.018A9.89,9.89,0,0,1,55-800a9.428,9.428,0,0,1,2.307.283,11.275,11.275,0,0,1,2.193.8.783.783,0,0,1,.375.339A.968.968,0,0,1,60-798.1v10.9a.873.873,0,0,1-.443.792.879.879,0,0,1-.92.023,8.248,8.248,0,0,0-1.761-.7A7.153,7.153,0,0,0,55-787.334a7.743,7.743,0,0,0-2,.26,7.457,7.457,0,0,0-1.864.78,2.549,2.549,0,0,1-.534.215A2.233,2.233,0,0,1,50-786Z" transform="translate(-40 800)" fill="#8e8fa2"/>
                                  </svg>                                  
                                {{ 'companies.Contact Person' | translate }}
                            </ng-template>
                            <div class="card">
                                <div class="card-head">
                                    <div class="card-title">
                                        <h4>{{ 'companies.Contact Person' | translate }}</h4>
                                    </div>
                                    <div class="card-actions">
                                        <button mat-icon-button (click)="updateVendorContactInfo()"
                                            [matTooltip]="'general.Edit' | translate">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <app-vendor-contact-info [vendorDetails]="vendorDetails"></app-vendor-contact-info>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<app-vendor-wallet-transaction *ngIf="selectedVendorId" [id]="selectedVendorId"
    [currentPoints]="vendorDetails.balance.points"
    (close)="selectedVendorId = null;"></app-vendor-wallet-transaction>