import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../../services/api-service.service';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorProfileService {

  constructor(private _ApiService: ApiServiceService) { }

  GetVendorProfile(id: string) {
    return this._ApiService.get(`${environment.apiUrl}/vendors/profile/?vendor_id=${id}`)
      .pipe(catchError(this.handleError));
  }

  updateVendorAddress(id: string, data: any) {
    return this._ApiService.update(`${environment.apiUrl}/vendor-admin/update-address-for-vendor/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  updateVendorContactInfo(id: string, data: any) {
    return this._ApiService.update(`${environment.apiUrl}/vendor-admin/update-contacts-for-vendor/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  updateVendorCategories(id: string, data: any) {
    return this._ApiService.update(`${environment.apiUrl}/vendor-admin/update-categories-for-vendor/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }
}