<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{ 'vendorAdminProfile.Update contact information' | translate }}
    </div>
    <form [formGroup]="formGroup">
        <div class="dialog_content pb-0" mat-dialog-content>
            <div class="title">{{"companies.Contact Person"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="phoneNumberleft">
                            <mat-label>{{ "general.Mobile Number" | translate }}</mat-label>
                            <input matInput type="text" appOnlynumber maxlength="10" minlength="8"
                                formControlName="vendor_phone_number">
                            <span matSuffix>+966 </span>
                            <mat-hint class="pt-1">{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('vendor_phone_number','minlength')">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"forgetPassword.Email"|translate}}</mat-label>
                            <input matInput type="email" maxlength="100" formControlName="vendor_email">
                            <mat-error *ngIf="hasError('vendor_email','pattern')">
                                {{"form_message.Invalid email"|translate}}
                            </mat-error>
                            <mat-hint class="pt-1">{{"general.optional"|translate}}</mat-hint>

                        </mat-form-field>
                    </div>
                    <div class="title">{{"vendorAdminProfile.Social media channels"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendors.Facebook"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="facebook_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/facebook.svg"
                                    alt="facebook Icon"></span>
                            <mat-hint class="pt-1">{{"general.optional"|translate}}</mat-hint>
                            <!-- <mat-error *ngIf="hasError('facebook_link','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error> -->
                            <mat-error *ngIf="hasError('facebook_link','pattern')">
                                {{"forms.Invalid link"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendors.Instagram"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="instagram_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/instagram.png"
                                    alt="instegram Icon"></span>
                            <!-- <mat-error *ngIf="hasError('instagram_link','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error> -->
                            <mat-hint class="pt-1">{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('instagram_link','pattern')">
                                {{"forms.Invalid link"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendors.X"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="x_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/twitter.svg"
                                    alt="twitter Icon"></span>
                            <!-- <mat-error *ngIf="hasError('x_link','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error> -->
                            <mat-hint class="pt-1">{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('x_link','pattern')">
                                {{"forms.Invalid link"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
        </div>
    </form>
    <div class="dialog_actions" mat-dialog-actions>
        <button mat-button (click)="openConfirmDialog()" [disabled]="loading"
            class="btn-secondary">{{"general.Cancel"|translate}}</button>
        <button mat-button class="btn-primary" [disabled]="loading" [class.loading]="loading" (click)="saveRecord()">
            {{ 'vendorAdminProfile.Update' | translate }}</button>
    </div>
</div>